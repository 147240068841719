// src/stores/counter.js
import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', {
  // 定义 state，类似于组件中的 data
  state: () => ({
    downloadId:'1888875947587932160',
    publicVapidKey : 'BCdW3i72BnfvXEB7UnwU-unHpFv-rasRZ_SJxYHIFfiw3VrVnQ7TR4qVs_GbxNJq5LQ0LJ-un6Q3Tw42iyLKVY8',
    downloadUrl : '/prod-api',
    type:'facebook',
    pixelCode:'1201674067761049',
    model:'模板一',
    // appurl:'https://test.nikozhou.com',
    appurl:'https://test.nikozhou.com',
    urltype:'1',
  }),
});
